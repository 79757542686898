import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        sentidos: [],
        sentido: []
    }),
    mutations: {
        createSentido (state, item){
            state.sentidos.push(item)
        },
        updateSentido (state, item){
            let pos = state.sentidos.findIndex(e => e.id == item.id)
            state.sentidos.splice(pos, 1, item)
        },
        deleteSentido(state, index){
            state.sentidos.splice(index, 1)
        },
        showSentido(state, sentido){
            let pos = state.sentidos.findIndex(e => e.id == sentido.id)
            sentido.mostrar = 1
            state.sentidos.splice(pos, 1, sentido)
        },
        hideSentido(state, sentido){
            let pos = state.sentidos.findIndex(e => e.id == sentido.id)
            sentido.mostrar = 0
            state.sentidos.splice(pos, 1, sentido)
        },
        setSentidos(state, sentidos){
            state.sentidos = sentidos
        }
    },
    actions: {
        async createSentido (context, sentido){
            try{
                const data = await axios.post(context.rootState.base_url + 'sentidos', context.rootState.headers)
                context.commit("createSentido", data)
            }catch(error){
                console.log(error)
            }
        },
        async updateSentido (context, sentido){
            try{
                const data = await axios.put(context.rootState.base_url + `sentidos/${sentido.id}`, context.rootState.headers)
                context.commit("updateSentido", data)
            }catch(error){
                console.log(error)
            }
        },
        async deleteSentido(context, sentido){
            try{
                const data = await axios.delete(context.rootState.base_url + `sentidos/${sentido.id}`, context.rootState.headers)
                context.commit("deleteSentido", sentido.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchSentidos(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'sentidos', context.rootState.headers)
                context.commit("setSentidos", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.sentidos.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.sentidos.find(e => e.id == id)
        },
        getAll: state => {
            return state.sentidos
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */