import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        centroCostos: [],
        centroCosto: []
    }),
    mutations: {
        createCentroCosto (state, item){
            state.centroCostos.push(item)
        },
        updateCentroCosto (state, item){
            let pos = state.centroCostos.findIndex(e => e.id == item.id)
            state.centroCostos.splice(pos, 1, item)
        },
        deleteCentroCosto(state, index){
            state.centroCostos.splice(index, 1)
        },
        showCentroCosto(state, centroCosto){
            let pos = state.centroCostos.findIndex(e => e.id == centroCosto.id)
            centroCosto.mostrar = 1
            state.centroCostos.splice(pos, 1, centroCosto)
        },
        hideCentroCosto(state, centroCosto){
            let pos = state.centroCostos.findIndex(e => e.id == centroCosto.id)
            centroCosto.mostrar = 0
            state.centroCostos.splice(pos, 1, centroCosto)
        },
        setCentroCostos(state, centroCostos){
            state.centroCostos = centroCostos
        }
    },
    actions: {
        async createCentroCosto (context, centroCosto){
            try{
                const data = await axios.post(context.rootState.base_url + 'centros_costos', context.rootState.headers)
                context.commit("createCentroCosto", data)
            }catch(error){
                console.log(error)
            }
        },
        async updateCentroCosto (context, centroCosto){
            try{
                const data = await axios.put(context.rootState.base_url + `centros_costos/${centroCosto.id}`, context.rootState.headers)
                context.commit("updateCentroCosto", data)
            }catch(error){
                console.log(error)
            }
        },
        async deleteCentroCosto(context, centroCosto){
            try{
                const data = await axios.delete(context.rootState.base_url + `centros_costos/${centroCosto.id}`, context.rootState.headers)
                context.commit("deleteCentroCosto", centroCosto.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchCentroCostos(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'centros_costos', context.rootState.headers)
                context.commit("setCentroCostos", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.centroCostos.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.centroCostos.find(e => e.id == id)
        },
        getAll: state => {
            return state.centroCostos
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */