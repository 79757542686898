import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        tipoOrigenes: [],
        tipoOrigen: []
    }),
    mutations: {
        createTipoOrigen (state, item){
            state.tipoOrigenes.push(item)
        },
        updateTipoOrigen (state, item){
            let pos = state.tipoOrigenes.findIndex(e => e.id == item.id)
            state.tipoOrigenes.splice(pos, 1, item)
        },
        deleteTipoOrigen(state, index){
            state.tipoOrigenes.splice(index, 1)
        },
        showTipoOrigen(state, tipoOrigen){
            let pos = state.tipoOrigenes.findIndex(e => e.id == tipoOrigen.id)
            tipoOrigen.mostrar = 1
            state.tipoOrigenes.splice(pos, 1, tipoOrigen)
        },
        hideTipoOrigen(state, tipoOrigen){
            let pos = state.tipoOrigenes.findIndex(e => e.id == tipoOrigen.id)
            tipoOrigen.mostrar = 0
            state.tipoOrigenes.splice(pos, 1, tipoOrigen)
        },
        setTipoOrigens(state, tipoOrigenes){
            state.tipoOrigenes = tipoOrigenes
        }
    },
    actions: {
        async createTipoOrigen (context, tipoOrigen){
            try{
                const data = await axios.post(context.rootState.base_url + 'tipo_origenes', context.rootState.headers)
                context.commit("createTipoOrigen", data)
            }catch(error){
                console.log(error)
            }
        },
        async updateTipoOrigen (context, tipoOrigen){
            try{
                const data = await axios.put(context.rootState.base_url + `tipo_origenes/${tipoOrigen.id}`, context.rootState.headers)
                context.commit("updateTipoOrigen", data)
            }catch(error){
                console.log(error)
            }
        },
        async deleteTipoOrigen(context, tipoOrigen){
            try{
                const data = await axios.delete(context.rootState.base_url + `tipo_origenes/${tipoOrigen.id}`, context.rootState.headers)
                context.commit("deleteTipoOrigen", tipoOrigen.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchTipoOrigenes(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'tipo_origenes', context.rootState.headers)
                context.commit("setTipoOrigens", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.tipoOrigenes.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.tipoOrigenes.find(e => e.id == id)
        },
        getAll: state => {
            return state.tipoOrigenes
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */