import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        tipoDestinos: [],
        tipoDestino: []
    }),
    mutations: {
        createTipoDestino (state, item){
            state.tipoDestinos.push(item)
        },
        updateTipoDestino (state, item){
            let pos = state.tipoDestinos.findIndex(e => e.id == item.id)
            state.tipoDestinos.splice(pos, 1, item)
        },
        deleteTipoDestino(state, index){
            state.tipoDestinos.splice(index, 1)
        },
        showTipoDestino(state, tipoDestino){
            let pos = state.tipoDestinos.findIndex(e => e.id == tipoDestino.id)
            tipoDestino.mostrar = 1
            state.tipoDestinos.splice(pos, 1, tipoDestino)
        },
        hideTipoDestino(state, tipoDestino){
            let pos = state.tipoDestinos.findIndex(e => e.id == tipoDestino.id)
            tipoDestino.mostrar = 0
            state.tipoDestinos.splice(pos, 1, tipoDestino)
        },
        setTipoDestinos(state, tipoDestinos){
            state.tipoDestinos = tipoDestinos
        }
    },
    actions: {
        async createTipoDestino (context, tipoDestino){
            try{
                const data = await axios.post(context.rootState.base_url + 'tipo_destinos', tipoDestino, context.rootState.headers)
                context.commit("createTipoDestino", data)
            }catch(error){
                console.log(error)
            }
        },
        async updateTipoDestino (context, tipoDestino){
            try{
                const data = await axios.put(context.rootState.base_url + `tipo_destinos/${tipoDestino.id}`, context.rootState.headers)
                context.commit("updateTipoDestino", data)
            }catch(error){
                console.log(error)
            }
        },
        async deleteTipoDestino(context, tipoDestino){
            try{
                const data = await axios.delete(context.rootState.base_url + `tipo_destinos/${tipoDestino.id}`, context.rootState.headers)
                context.commit("deleteTipoDestino", tipoDestino.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchTipoDestinos(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'tipo_destinos', context.rootState.headers)
                context.commit("setTipoDestinos", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.tipoDestinos.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.tipoDestinos.find(e => e.id == id)
        },
        getAll: state => {
            return state.tipoDestinos
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */