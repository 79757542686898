import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        tipoPartes: [],
        tipoParte: []
    }),
    mutations: {
        createTipoParte (state, item){
            state.tipoPartes.push(item)
        },
        updateTipoParte (state, item){
            let pos = state.tipoPartes.findIndex(e => e.id == item.id)
            state.tipoPartes.splice(pos, 1, item)
        },
        deleteTipoParte(state, index){
            state.tipoPartes.splice(index, 1)
        },
        showTipoParte(state, tipoParte){
            let pos = state.tipoPartes.findIndex(e => e.id == tipoParte.id)
            tipoParte.mostrar = 1
            state.tipoPartes.splice(pos, 1, tipoParte)
        },
        hideTipoParte(state, tipoParte){
            let pos = state.tipoPartes.findIndex(e => e.id == tipoParte.id)
            tipoParte.mostrar = 0
            state.tipoPartes.splice(pos, 1, tipoParte)
        },
        setTipoPartes(state, tipoPartes){
            state.tipoPartes = tipoPartes
        }
    },
    actions: {
        async createTipoParte (context, tipoParte){
            try{
                const data = await axios.post(context.rootState.base_url + 'tipo_partes', context.rootState.headers)
                context.commit("createTipoParte", data)
            }catch(error){
                console.log(error)
            }
        },
        async updateTipoParte (context, tipoParte){
            try{
                const data = await axios.put(context.rootState.base_url + `tipo_partes/${tipoParte.id}`, context.rootState.headers)
                context.commit("updateTipoParte", data)
            }catch(error){
                console.log(error)
            }
        },
        async deleteTipoParte(context, tipoParte){
            try{
                const data = await axios.delete(context.rootState.base_url + `tipo_partes/${tipoParte.id}`, context.rootState.headers)
                context.commit("deleteTipoParte", tipoParte.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchTipoPartes(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'tipo_partes', context.rootState.headers)
                context.commit("setTipoPartes", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.tipoPartes.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.tipoPartes.find(e => e.id == id)
        },
        getAll: state => {
            return state.tipoPartes
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */