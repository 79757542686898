import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        articulos: [],
        articulo: []
    }),
    mutations: {
        createArticulo (state, item){
            state.articulos.push(item)
        },
        updateArticulo (state, item){
            let pos = state.articulos.findIndex(e => e.id == item.id)
            state.articulos.splice(pos, 1, item)
        },
        deleteArticulo(state, index){
            state.articulos.splice(index, 1)
        },
        showArticulo(state, articulo){
            let pos = state.articulos.findIndex(e => e.id == articulo.id)
            articulo.mostrar = 1
            state.articulos.splice(pos, 1, articulo)
        },
        hideArticulo(state, articulo){
            let pos = state.articulos.findIndex(e => e.id == articulo.id)
            articulo.mostrar = 0
            state.articulos.splice(pos, 1, articulo)
        },
        setArticulos(state, articulos){
            state.articulos = articulos
        }
    },
    actions: {
        async createArticulo (context, articulo){
            try{
                const data = await axios.post(context.rootState.base_url + 'articulos', context.rootState.headers)
                context.commit("createArticulo", data)
            }catch(error){
                console.log(error)
            }
        },
        async updateArticulo (context, articulo){
            try{
                const data = await axios.put(context.rootState.base_url + `articulos/${articulo.id}`, context.rootState.headers)
                context.commit("updateArticulo", data)
            }catch(error){
                console.log(error)
            }
        },
        async deleteArticulo(context, articulo){
            try{
                const data = await axios.delete(context.rootState.base_url + `articulos/${articulo.id}`, context.rootState.headers)
                context.commit("deleteArticulo", articulo.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchArticulos(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'articulos', context.rootState.headers)
                context.commit("setArticulos", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.articulos.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.articulos.find(e => e.id == id)
        },
        getAll: state => {
            return state.articulos
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */