import Vue from "vue";
//import App from './App.vue'
import Main from "./Main.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(VueSweetalert2);

//dayjs
import dayjs from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
dayjs.extend(isSameOrBefore);

import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert);

import DatetimePicker from "vuetify-datetime-picker";
Vue.use(DatetimePicker);

import VDigitalTimePicker from "v-digital-time-picker";
Vue.use(VDigitalTimePicker);

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import moment from "moment";
Vue.prototype.moment = moment;

import VuetifyConfirm from "vuetify-confirm";
Vue.use(VuetifyConfirm, { vuetify, buttonTrueText: "SI" });

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

Vue.config.productionTip = false;

import DataTable from "@/components/base/DataTable.vue";
Vue.component("datatable", DataTable);

import BotoneraMantenedor from "@/components/base/BotoneraMantenedor.vue";
Vue.component("botonera", BotoneraMantenedor);

import Spinner from "@/components/Spinner.vue";
Vue.component("spinner",Spinner);

import numeroALetras from "numeros_a_letras";
Vue.prototype.numeroALetras = numeroALetras;

import Donut from "vue-css-donut-chart";
import "vue-css-donut-chart/dist/vcdonut.css";
Vue.use(Donut);

//estilos propios globales
import "./styles/global.css";

Vue.filter("format_fecha", function(value) {
  if (!value) return "";
  if (value.includes(":")) return dayjs(value).format("DD-MM-YYYY");
  return dayjs(value + " 00:00:00").format("DD-MM-YYYY");
});

Vue.filter("format_date_spanish", function(value) {
  if (!value) return "";
  if (value.includes(":")) return dayjs(value).format("DD-MM-YYYY HH:mm");
  return dayjs(value + " 00:00:00").format("DD-MM-YYYY HH:mm");
});

Vue.filter("format_fecha_hora", function(value) {
  if (value == null) return "";
  if (typeof value == "object") value = value.toString();
  //console.log("value", value);

  if (!value) return "";
  if (value.includes(":")) return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
  return dayjs(value + " 00:00:00").format("YYYY-MM-DD HH:mm:ss");
});

Vue.filter("format_string_a_fecha", function(value) {
  //console.log('fechita',value)
  if (!value) return null;
  return new Date(value);
});

Vue.filter('formatear_miles', function (valor) {
  if (!valor) return '0'
  if (typeof valor == 'string') {

      valor = Number(valor);
  }
  let result = valor.toLocaleString("es-CL", { minimumFractionDigits: 0, maximumFractionDigits: 0 });
  return result;
})

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(Main),
}).$mount("#app");
