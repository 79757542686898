import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        especies: [],
        especie: []
    }),
    mutations: {
        createEspecie (state, item){
            state.especies.push(item)
        },
        updateEspecie (state, item){
            let pos = state.especies.findIndex(e => e.id == item.id)
            state.especies.splice(pos, 1, item)
        },
        deleteEspecie(state, index){
            state.especies.splice(index, 1)
        },
        showEspecie(state, especie){
            let pos = state.especies.findIndex(e => e.id == especie.id)
            especie.mostrar = 1
            state.especies.splice(pos, 1, especie)
        },
        hideEspecie(state, especie){
            let pos = state.especies.findIndex(e => e.id == especie.id)
            especie.mostrar = 0
            state.especies.splice(pos, 1, especie)
        },
        setEspecies(state, especies){
            state.especies = especies
        }
    },
    actions: {
        async createEspecie (context, especie){
            try{
                const data = await axios.post(context.rootState.base_url + 'especies', context.rootState.headers)
                context.commit("createEspecie", data)
            }catch(error){
                console.log(error)
            }
        },
        async updateEspecie (context, especie){
            try{
                const data = await axios.put(context.rootState.base_url + `especies/${especie.id}`, context.rootState.headers)
                context.commit("updateEspecie", data)
            }catch(error){
                console.log(error)
            }
        },
        async deleteEspecie(context, especie){
            try{
                const data = await axios.delete(context.rootState.base_url + `especies/${especie.id}`, context.rootState.headers)
                context.commit("deleteEspecie", especie.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchEspecies(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'especies', context.rootState.headers)
                context.commit("setEspecies", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.especies.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.especies.find(e => e.id == id)
        },
        getAll: state => {
            return state.especies
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */