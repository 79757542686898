<template>
  <v-app>
    <div :class="['wrapper', $router.currentRoute.fullPath === '/' ? 'fondito' : '']">
      <span style="color:white;position:fixed;z-index: 10;bottom: 5px; left: 5px; font-size: 1em; font-weight: 500;">{{
        version.version }}</span>
      <div class="sidebar" data-color="blue" data-background-color="black" v-if="token">

        <div class="logo"><a @click="navigate('dashboard')" class="simple-text logo-mini">
            <i class="material-icons">directions_boat</i>
          </a>
          <a @click="navigate('dashboard')" class="simple-text logo-normal">
            SISTEMA WELLBOAT
          </a>
        </div>
        <div class="sidebar-wrapper">
          <ul class="nav">
            <span v-for="(item, index) in user.menus" :key="index">
              <li class="nav-item " v-if="item.type == 'single' && item.ver == 1">
                <a :class="`nav-link ${item.active?'bg-active':''}`" @click="navigate(item.path)">
                  <i class="material-icons">{{ item.icon }}</i>
                  <p> {{ item.text }}</p>
                </a>
              </li>
              <li class="nav-item " v-if="item.type == 'group' && hasChildren(item)">
                <a class="nav-link" data-toggle="collapse" :href="`#${index}-${item.text}`">
                  <i class="material-icons">{{ item.icon }}</i>
                  <p> {{ item.text }}
                    <b class="caret"></b>
                  </p>
                </a>
                <div class="collapse" :id="`${index}-${item.text}`">
                  <ul class="nav">
                    <span v-for="(child, idx) in item.children" :key="idx">
                      <li class="nav-item" v-if="child.type == 'single' && child.ver == 1">
                        <a :class="`nav-link ${child.active?'bg-active':''}`" @click="navigate(child.path)">
                          <i class="material-icons">{{ child.icon }}</i>
                          <p> {{ child.text }}</p>
                        </a>
                      </li>
                      <li class="nav-item " v-if="child.type == 'group' && hasChildren(child)">
                        <a class="nav-link" data-toggle="collapse" :href="`#${index}-${child.text}`">
                          <i class="material-icons">{{ child.icon }}</i>
                          <p> {{ child.text }}
                            <b class="caret"></b>
                          </p>
                        </a>
                        <div class="collapse" :id="`${index}-${child.text}`">
                          <ul class="nav">
                            <span v-for="(child1, idx) in child.children" :key="idx">
                              <li class="nav-item " v-if="child1.type == 'single' && child1.ver == 1">
                                <a class="nav-link" @click="navigate(child1.path)">
                                  <i class="material-icons">{{ child1.icon }}</i>
                                  <p> {{ child1.text }}</p>
                                </a>
                              </li>
                              <li class="nav-item " v-if="child1.type == 'group' && hasChildren(child1)">
                                <a class="nav-link" data-toggle="collapse" :href="`#${index}-${child1.text}`">
                                  <i class="material-icons">{{ child1.icon }}</i>
                                  <p> {{ child1.text }}
                                    <b class="caret"></b>
                                  </p>
                                </a>
                                <div class="collapse" :id="`${index}-${child1.text}`">
                                  <ul class="nav">
                                    <span v-for="(child2, idx) in child1.children" :key="idx">
                                      <li class="nav-item " v-if="child2.type == 'single' && child2.ver == 1">
                                        <a class="nav-link" @click="navigate(child2.path)">
                                          <i class="material-icons">{{ child2.icon }}</i>
                                          <p> {{ child2.text }}</p>
                                        </a>
                                      </li>
                                      <li class="nav-item " v-if="child2.type == 'group' && hasChildren(child2)">
                                        <a class="nav-link" data-toggle="collapse" :href="`#${index}-${child2.text}`">
                                          <i class="material-icons">{{ child2.icon }}</i>
                                          <p> {{ child2.text }}
                                            <b class="caret"></b>
                                          </p>
                                        </a>
                                        <div class="collapse" :id="`${index}-${child2.text}`">
                                          <ul class="nav">
                                            <span v-for="(child3, idx) in child2.children" :key="idx">
                                              <li class="nav-item " v-if="child3.type == 'single' && child3.ver == 1">
                                                <a class="nav-link" @click="navigate(child3.path)">
                                                  <i class="material-icons">{{ child3.icon }}</i>
                                                  <p> {{ child3.text }}</p>
                                                </a>
                                              </li>
                                              <!-- Aqui iría otro nivel -->
                                            </span>
                                          </ul>
                                        </div>
                                      </li>
                                    </span>
                                  </ul>
                                </div>
                              </li>
                            </span>
                          </ul>
                        </div>
                      </li>
                    </span>
                  </ul>
                </div>
              </li>
            </span>
          </ul>
        </div>
        <div class="sidebar-background"></div>
      </div>

      <div :class="['main-panel', $router.currentRoute.fullPath === '/' ? 'cien' : '']">
        <!-- Navbar -->
        <nav class="navbar navbar-expand-lg navbar-transparent navbar-absolute fixed-top " v-show="token">
          <div class="container-fluid">
            <div class="navbar-wrapper">
              <div class="navbar-minimize">
                <button id="minimizeSidebar" class="btn btn-just-icon btn-white btn-fab btn-round">
                  <i class="material-icons text_align-center visible-on-sidebar-regular">more_vert</i>
                  <i class="material-icons design_bullet-list-67 visible-on-sidebar-mini">view_list</i>
                </button>
              </div>
              <a class="navbar-brand" href="javascript:;" v-if="user.wellboat">Wellboat: {{ user.wellboat.nombre }}</a>
            </div>
            <button class="navbar-toggler" type="button" data-toggle="collapse" aria-controls="navigation-index"
              aria-expanded="false" aria-label="Toggle navigation">
              <span class="sr-only">Toggle navigation</span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
              <span class="navbar-toggler-icon icon-bar"></span>
            </button>
            <div class="collapse navbar-collapse justify-content-end">
              <ul class="navbar-nav">
                <li class="nav-item dropdown">
                  <a class="nav-link" href="javascript:;" id="navbarDropdownProfile" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">person</i>
                    Usuario: {{ user ? user.name : '' }}
                    <p class="d-lg-none d-md-block">
                      Account
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownProfile">
                    <div v-if="user.rol && (user.rol.id == 1 || user.rol.id == 4)">
                      <a class="dropdown-item" href=""
                        @click.prevent="changeWellboat({ id: 0, nombre: 'Todos' })">WELLBOATS</a>
                      <div class="dropdown-divider" />
                      <a class="dropdown-item" href="" v-for="w in wellboats" v-bind:key="w.id"
                        @click.prevent="changeWellboat(w)">{{ w.nombre }}</a>
                    </div>
                    <div class="dropdown-divider" />
                    <a class="dropdown-item" href="" @click="cerrarSession()">Salir</a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <!-- End Navbar -->

        <div class="content">
          <div class="content">
            <div class="container-fluid">
              <router-view :key="viewKey" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
//import $ from 'jquery'
import { mapState, mapMutations, mapActions } from 'vuex'
export default {
  props: {
    source: String,
  },
  data: () => ({
    dialog: false,
    drawer: false,
    //wellboats: [],
    viewKey: 1,
    version: {
      version: "2.0.0",
      backend: "",
      frontend: ""
    },
    versiones: [
      {
        //version de nueva bitacora de carga
        version: "1.0.2",
        backend: "f4f68f690d6aa94840fed57986878eceb9678b97",
        frontend: "0a139130cf6a49ce9162f8a00fc705725687e289"
      },
      {
        //version Actualizacion 20/11/2023
        version: "2.0.0",
        backend: "a08fbed0f2146fb0bda24ea206db469679a14867",
        frontend: "b43e5054ab6ee436a7e0f2112ad222857061e246"
      }
    ]
  }),
  mounted() {
    this.detectarIP();
    //this.loadWellboats();
    document.title = 'LAPSA | WELLBOATS';
    this.$store.commit('initializeStore')
    console.log('env', process.env.VUE_APP_USAR_ROUTER_INDEX_JS);
  },
  beforeUpdate() {
    /*
    if(this.$router.currentRoute.fullPath === '/'){
      $('#panel_principal').addClass('cien');
      $('.wrapper').addClass('fondito');
    }else{  
      $('#panel_principal').removeClass('cien');
      $('.wrapper').removeClass('fondito');
    }
    */
  },
  methods: {
    hasChildren(item) {
      if (item.children == undefined) return false;
      let suma = 0;
      item.children.forEach(ch => {
        if (ch.type == 'group') {
          suma += 1;
        } else {
          suma += ch.ver;
        }
      });
      //if(item.type == 'group' && item.text == 'Ayuda') console.log('suma ' + item.text + ': ', suma);
      return suma > 0 ? true : false;
    },
    detectarIP() {
      //let url_actual = window.location.href;
      let custom_url = process.env.VUE_APP_BACKEND_URL;
      let back = '';
      let back_storage = '';
      //console.log('backend url',custom_url)
      if (custom_url) {
        if (custom_url.includes('/public')) {
          //config barcos
          back = `${custom_url}/api/`
          back_storage = `${custom_url.replace('/public', '')}/storage/app/public/`
        } else {
          //wellboat.avanze.cl
          back = `${custom_url}/api/`
          back_storage = `${custom_url}/storage/public/`
        }
        // this.actualizarBaseUrl(`${custom_url}/api/`);
        // this.actualizarUrlStorage(`${custom_url.replace('/public','')}/storage/app/public/`);
        this.actualizarBaseUrl(back);
        this.actualizarUrlStorage(back_storage);
      } /*else if (url_actual.includes('localhost')) {
        this.actualizarBaseUrl('http://localhost/wellboat/wellboat_backend/public/api/');
        this.actualizarUrlStorage('http://localhost/wellboat/wellboat_backend/storage/app/public/');
      } else if (url_actual.includes('avanze.cl')) {
        //esta en un wellboat
        this.actualizarBaseUrl('https://www.avanze.cl/develop/lapsa/wellboat/backend/public/api/');
        this.actualizarUrlStorage('https://www.avanze.cl/develop/lapsa/wellboat/backend/storage/app/public/');
      } else if (url_actual.includes('192.168.0.6')) {
        //esta en la red local de producción
        this.actualizarBaseUrl('http://192.168.0.6/sistema/wellboat_backend/public/api/');
        this.actualizarUrlStorage('http://192.168.0.6/sistema/wellboat_backend/storage/app/public/');
      } else if (url_actual.includes('wellboatvue.local')) {
        this.actualizarBaseUrl('http://wellboat.local/api/');
      }*/

      console.log(`backend: ${back}`)
      console.log(`backend storage: ${back_storage}`)
    },
    changeWellboat(w) {
      this.user.wellboat = w;
      this.actualizarWellboat(w);
      // viewKey evita el error de ruta duplicada
      this.viewKey += 1;
      // se recarga ruta, catch evita mostrar el error
      this.$router.push(this.$router.currentRoute).catch(() => { });
    },
    async loadWellboats() {
      let url = `${this.base_url}wellboats/lista/select`;
      await this.axios.get(url, this.headers).then((response) => {
        this.wellboats = response.data;
      }).catch((error) => {
        console.log(error);
      });
    },
    ...mapActions(['cerrarSession']),
    ...mapMutations(['actualizarBaseUrl', 'actualizarUrlStorage', 'actualizarWellboat']),
    navigate(route) {
      //this.$router.push({name:route});
      this.user.menus.forEach(m => {
        if(m.type == 'group'){
          m.children.forEach(sb => {
            if(route == sb.path){
              sb.active = true;
            }else{
              sb.active = false;
            }
          })
        }else{
          if(route == m.path){
              m.active = true;
            }else{
              m.active = false;
            }
        }
      })
      console.log('route',route);
      this.$router.push({ name: route }).catch(err => {
        // Ignore the vuex err regarding  navigating to the page they are already on.
        if (err.name !== 'NavigationDuplicated' && !err.message.includes('Avoided redundant navigation to current location')) {
          console.log(err);
        }
      });
    }
  },
  computed: {
    ...mapState(['token', 'loading', 'url_socket', 'pageTitle', 'wellboat', 'user', 'base_url', 'headers','wellboats'])
  },
  components: {
    //Loader,
  }
}
</script>
<style>
@import './assets/css/material-dashboard.css';
@import './assets/demo/demo.css';

.bg-active{
  color: #ffffff !important;
  background-color:rgba(200, 200, 200, 0.288);
}
.bg-active:hover{
  color: #ffffff !important;
  background-color:rgba(200, 200, 200, 0.288);
}

.sidebar[data-background-color=black] {
  background-color: #6eaef3 !important;
  background-image: url("./assets/img/mar-1.jpg");
}

.sidebar {
  background-image: url("./assets/img/sidebar-1.jpg");
}

.sidebar[data-color="blue"] li.active>a {
  background-color: #1E88E5;
  box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4);
}

.v-application ul,
.v-application ol {
  padding-left: 0px !important;
}

.theme--light.v-application {
  background: #eee !important;
  color: rgba(0, 0, 0, 0.87);
}

.dropdown-menu .dropdown-item:hover,
.dropdown-menu .dropdown-item:focus,
.dropdown-menu a:hover,
.dropdown-menu a:focus,
.dropdown-menu a:active {
  box-shadow: 0 4px 20px 0px #1974bf, 0 7px 10px -5px #2d95e9;
  background-color: #42A5F5;
  color: #FFFFFF;
}

.hidden {
  display: none;
}

.fondito {
  /* background: rgb(2,0,36) !important;
  background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(9,9,121,1) 35%, rgba(0,212,255,1) 100%) !important; */

  /* background: rgb(2,0,36) !important;
  background: linear-gradient(90deg, rgba(2,0,36,1) 3%, rgba(9,66,121,1) 43%, rgba(0,202,255,1) 100%) !important; */

  /* background: rgb(9,35,60) !important;
  background: linear-gradient(90deg, rgba(9,35,60,0.8855917366946778) 0%, rgba(9,66,121,0.773546918767507) 48%, rgba(0,206,255,1) 100%) !important; */
  /* background-image: url("./assets/img/mar-1.jpg") !important; */
  background-color: #eee;
  color: #3C4858;
  font-weight: 300;
  background-image: url('./assets/img/fondo_login.jpg');
  background-size: cover;
  background-position: top center;
  opacity: 0.85;
}

button[type="button"] {
  /* width:120px;
    height:60px;
    margin-left:35px;
    display:block;
    background-color:gray;
    color:white; */
  /* border: none !important; */
  outline: none !important;
}

.titulo-label {
  color: #3c3c3c;
  font-size: medium;
  margin-bottom: 1em;
}

.shadow {
  border: 1px solid #3c3c3c;
}

.bg-deep-blue {
  background-color: #285897 !important;
  color: #fff !important;
}

.bg-deep-blue:hover {
  background-color: #5375a1 !important;

}</style>

