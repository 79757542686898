import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        acopios: [],
        acopio: []
    }),
    mutations: {
        createAcopio (state, item){
            state.acopios.push(item)
        },
        updateAcopio (state, item){
            let pos = state.acopios.findIndex(e => e.id == item.id)
            state.acopios.splice(pos, 1, item)
        },
        deleteAcopio(state, index){
            state.acopios.splice(index, 1)
        },
        showAcopio(state, acopio){
            let pos = state.acopios.findIndex(e => e.id == acopio.id)
            acopio.mostrar = 1
            state.acopios.splice(pos, 1, acopio)
        },
        hideAcopio(state, acopio){
            let pos = state.acopios.findIndex(e => e.id == acopio.id)
            acopio.mostrar = 0
            state.acopios.splice(pos, 1, acopio)
        },
        setAcopios(state, acopios){
            state.acopios = acopios
        }
    },
    actions: {
        async createAcopio (context, acopio){
            try{
                const data = await axios.post(context.rootState.base_url + 'acopios', context.rootState.headers)
                context.commit("createAcopio", data)
            }catch(error){
                console.log(error)
            }
        },
        async updateAcopio (context, acopio){
            try{
                const data = await axios.put(context.rootState.base_url + `acopios/${acopio.id}`, context.rootState.headers)
                context.commit("updateAcopio", data)
            }catch(error){
                console.log(error)
            }
        },
        async deleteAcopio(context, acopio){
            try{
                const data = await axios.delete(context.rootState.base_url + `acopios/${acopio.id}`, context.rootState.headers)
                context.commit("deleteAcopio", acopio.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchAcopios(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'acopios', context.rootState.headers)
                context.commit("setAcopios", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.acopios.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.acopios.find(e => e.id == id)
        },
        getAll: state => {
            return state.acopios
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */