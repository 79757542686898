import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component:  () => import('@/views/Login.vue')
    //redirect: '/login',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/Dashboard.vue')
  },
  {
    path: '/dashboard2',
    name: 'dashboard2',
    component: () => import('@/views/Dashboard2.vue')
  },
  {
    path: '/acopios',
    name: 'acopios',
    component:  () => import('@/views/Maestros/Acopios.vue')
  },
  {
    path: '/cuentas',
    name: 'cuentas',
    component:  () => import('@/views/Maestros/Cuentas.vue')
  },
  {
    path: '/costos',
    name: 'costos',
    component:  () => import('@/views/Costos.vue')
  },
  {
    path: '/subcostos',
    name: 'subcostos',
    component:  () => import('@/views/Maestros/Subcostos.vue')
  },
  {
    path: '/articulos',
    name: 'articulos',
    component:  () => import('@/views/Maestros/Articulos.vue')
  },
  {
    path: '/asignar_articulos',
    name: 'asignar_articulos',
    component:  () => import('@/views/Maestros/AsignarArticulos.vue')
  },
  {
    path: '/categorias',
    name: 'categorias',
    component:  () => import('@/views/Maestros/Categorias.vue')
  },
  {
    path: '/materiales',
    name: 'materiales',
    component:  () => import('@/views/Maestros/Materiales.vue')
  },
  {
    path: '/estados_solicitud',
    name: 'estados_solicitud',
    component:  () => import('@/views/Maestros/EstadoSolicitud.vue')
  },
  {
    path: '/ayuda_partes',
    name: 'ayuda_partes',
    component:  () => import('@/views/Ayuda/AyudaPartes.vue')
  },
  {
    path: '/ayuda_servicios',
    name: 'ayuda_servicios',
    component:  () => import('@/views/Ayuda/AyudaServicios.vue')
  },
  {
    path: '/ayuda_acceso_sistema',
    name: 'ayuda_acceso_sistema',
    component:  () => import('@/views/Ayuda/AyudaAccesoSistema.vue')
  },
  {
    path: '/ayuda_movimiento_interno',
    name: 'ayuda_movimiento_interno',
    component:  () => import('@/views/Ayuda/AyudaMovimientoInterno.vue')
  },
  {
    path: '/ayuda_parte1_cultivo_cosecha',
    name: 'ayuda_parte1_cultivo_cosecha',
    component:  () => import('@/views/Ayuda/AyudaParte1CultivoCosecha.vue')
  },
  {
    path: '/ayuda_parte2_cultivo_cosecha',
    name: 'ayuda_parte2_cultivo_cosecha',
    component:  () => import('@/views/Ayuda/AyudaParte2CultivoCosecha.vue')
  },
  {
    path: '/ayuda_parte1_cultivo_smolt',
    name: 'ayuda_parte1_cultivo_smolt',
    component:  () => import('@/views/Ayuda/AyudaParte1CultivoSmolt.vue')
  },
  {
    path: '/ayuda_parte2_cultivo_smolt',
    name: 'ayuda_parte2_cultivo_smolt',
    component:  () => import('@/views/Ayuda/AyudaParte2CultivoSmolt.vue')
  },
  {
    path: '/ayuda_parte1_acopio',
    name: 'ayuda_parte1_acopio',
    component:  () => import('@/views/Ayuda/AyudaParte1Acopio.vue')
  },
  {
    path: '/ayuda_parte2_acopio',
    name: 'ayuda_parte2_acopio',
    component:  () => import('@/views/Ayuda/AyudaParte2Acopio.vue')
  },
  {
    path: '/ayuda_parte1_muelle',
    name: 'ayuda_parte1_muelle',
    component:  () => import('@/views/Ayuda/AyudaParte1Muelle.vue')
  },
  {
    path: '/ayuda_parte2_muelle',
    name: 'ayuda_parte2_muelle',
    component:  () => import('@/views/Ayuda/AyudaParte2Muelle.vue')
  },
  {
    path: '/ayuda_carga_cosecha',
    name: 'ayuda_carga_cosecha',
    component:  () => import('@/views/Ayuda/AyudaCargaCosecha.vue')
  },
  {
    path: '/ayuda_traslado_cosecha',
    name: 'ayuda_traslado_cosecha',
    component:  () => import('@/views/Ayuda/AyudaTrasladoCosecha.vue')
  },
  {
    path: '/ayuda_descarga_cosecha',
    name: 'ayuda_descarga_cosecha',
    component:  () => import('@/views/Ayuda/AyudaDescargaCosecha.vue')
  },
  {
    path: '/ayuda_carga_smolt',
    name: 'ayuda_carga_smolt',
    component:  () => import('@/views/Ayuda/AyudaCargaSmolt.vue')
  },
  {
    path: '/ayuda_traslado_smolt',
    name: 'ayuda_traslado_smolt',
    component:  () => import('@/views/Ayuda/AyudaTrasladoSmolt.vue')
  },
  {
    path: '/ayuda_descarga_smolt',
    name: 'ayuda_descarga_smolt',
    component:  () => import('@/views/Ayuda/AyudaDescargaSmolt.vue')
  },
  {
    path: '/ayuda_reporte_partes',
    name: 'ayuda_reporte_partes',
    component:  () => import('@/views/Ayuda/AyudaReportePartes.vue')
  },
  {
    path: '/bodegas_wellboat',
    name: 'bodegas_wellboat',
    component:  () => import('@/views/Maestros/BodegasWellboat.vue')
  },
  {
    path: '/centros',
    name: 'centros',
    component:  () => import('@/views/Maestros/Centros.vue')
  },
  {
    path: '/cierres',
    name: 'cierres',
    component:  () => import('@/views/Maestros/CierreFinanciero.vue')
  },
  {
    path: '/contratos',
    name: 'contratos',
    component:  () => import('@/views/Maestros/Contratos.vue')
  },
  {
    path: '/distancias',
    name: 'distancias',
    component:  () => import('@/views/Maestros/Distancias.vue')
  },
  {
    path: '/empresas',
    name: 'empresas',
    component:  () => import('@/views/Maestros/Empresas.vue')
  },
  {
    path: '/especies',
    name: 'especies',
    component:  () => import('@/views/Maestros/Especies.vue')
  },
  {
    path: '/estado_faenas',
    name: 'estado_faenas',
    component:  () => import('@/views/Maestros/EstadoFaenas.vue')
  },
  {
    path: '/estado_wellboat',
    name: 'estado_wellboat',
    component:  () => import('@/views/Maestros/EstadoWellboat.vue')
  },
  {
    path: '/para_wellboat',
    name: 'para_wellboat',
    component:  () => import('@/views/Maestros/ParaWellboat.vue')
  },
  {
    path: '/facturacion_wellboat',
    name: 'facturacion_wellboat',
    component:  () => import('@/views/Maestros/FacturacionWellboat.vue')
  },
  {
    path: '/parte_mortalidad',
    name: 'parte_mortalidad',
    //component:  () => import('@/views/Partes/Mortalidad.vue')
    component:  () => import('@/views/Partes/MortalidadNew.vue')
  },
  {
    path: '/parte_cosecha',
    name: 'parte_cosecha',
    // component:  () => import('@/views/Partes/Cosecha.vue')
    component:  () => import('@/views/Partes/CosechaNew2.vue')
  },
  {
    path: '/parte_bano',
    name: 'parte_bano',
    // component:  () => import('@/views/Partes/Bano.vue')
    component:  () => import('@/views/Partes/BanoVacunaNew.vue')
  },
  {
    path: '/parte_vacuna',
    name: 'parte_vacuna',
    // component:  () => import('@/views/Partes/Bano.vue')
    component:  () => import('@/views/Partes/VacunaNew.vue')
  },
  {
    path: '/parte_muestreo',
    name: 'parte_muestreo',
    component:  () => import('@/views/Partes/Muestreo.vue')
  },
  {
    path: '/parte_interno',
    name: 'parte_interno',
    //component:  () => import('@/views/Partes/Interno.vue')
    component:  () => import('@/views/Partes/InternoNew.vue')
  },
  {
    path: '/parte_smolt',
    name: 'parte_smolt',
    //component:  () => import('@/views/Partes/Smolt.vue')
    component:  () => import('@/views/Partes/SmoltNew.vue')
  },
  {
    path: '/facturacion',
    name: 'facturacion',
    component:  () => import('@/views/Facturacion.vue'),
  },
  {
    path: '/faenas',
    name: 'faenas',
    component:  () => import('@/views/Maestros/Faenas.vue')
  },
  {
    path: '/muelles',
    name: 'muelles',
    component:  () => import('@/views/Maestros/Muelles.vue')
  },
  {
    path: '/partes',
    name: 'partes',
    component:  () => import('@/views/Partes.vue'),
  },
  {
    path: '/permisos',
    name: 'permisos',
    component:  () => import('@/views/Maestros/Permisos.vue'),
  },
  {
    path: '/presupuesto_ventas',
    name: 'presupuesto_ventas',
    component:  () => import('@/views/Maestros/PresupuestoVentas.vue'),
  },
  {
    path: '/reporte_bitacoras',
    name: 'reporte_bitacoras',
    // component:  () => import('@/views/Reportes/ReporteBitacoras.vue'),
    component:  () => import('@/views/Reportes/ReporteBitacoraNew.vue'),
  },
  {
    path: '/reporte_facturacion',
    name: 'reporte_facturacion',
    component:  () => import('@/views/Reportes/ReporteFacturacion.vue'),
  },
  {
    path: '/reporte_partes',
    name: 'reporte_partes',
    component:  () => import('@/views/Reportes/ReportePartes.vue'),
  },
  {
    path: '/reporte_servicios_wellboats',
    name: 'reporte_servicios_wellboats',
    // component:  () => import('@/views/Reportes/ReporteServiciosWellboats.vue'),
    component:  () => import('@/views/Reportes/ReporteServiciosWellboatsNew.vue'),
  },
  {
    path: '/reporte_costos',
    name: 'reporte_costos',
    component:  () => import('@/views/Reportes/ReporteCostos.vue'),
  },
  {
    path: '/faena_cosecha',
    name: 'faena_cosecha',
    //component:  () => import('@/views/Servicios/FaenaCosecha.vue')
    component:  () => import('@/views/Servicios/FaenaCosechaFAN.vue')
  },
  {
    path: '/faena_mortalidad',
    name: 'faena_mortalidad',
    //component:  () => import('@/views/Servicios/Mortalidad.vue')
    component:  () => import('@/views/Servicios/FaenaMortalidad.vue')
  },
  {
    path: '/faena_bano_vacuna',
    name: 'faena_bano_vacuna',
    component:  () => import('@/views/Servicios/FaenaBanoVacuna.vue')
  },
  {
    path: '/carga_smolt',
    name: 'carga_smolt',
    //component:  () => import('@/views/Servicios/CargaSmolt.vue')
    //component:  () => import('@/views/Servicios/FaenaSmolt.vue')
    component:  () => import('@/views/Servicios/FaenaSmoltDobleDescarga.vue')
  },
  {
    path: '/tarifas',
    name: 'tarifas',
    component:  () => import('@/views/Maestros/Tarifas.vue')
  },
  {
    path: '/plantas',
    name: 'plantas',
    component:  () => import('@/views/Maestros/Plantas.vue')
  },
  {
    path: '/areas',
    name: 'areas',
    component:  () => import('@/views/Maestros/Areas.vue')
  },
  {
    path: '/tarifa_smolt',
    name: 'tarifa_smolt',
    component:  () => import('@/views/Maestros/TarifaSmolt.vue')
  },
  {
    path: '/tipo_acuerdos',
    name: 'tipo_acuerdos',
    component:  () => import('@/views/Maestros/TipoAcuerdos.vue')
  },
  {
    path: '/tipo_descargas',
    name: 'tipo_descargas',
    component:  () => import('@/views/Maestros/TipoDescargas.vue')
  },
  {
    path: '/tipo_partes',
    name: 'tipo_partes',
    component:  () => import('@/views/Maestros/TipoPartes.vue')
  },
  {
    path: '/barcos',
    name: 'barcos',
    component:  () => import('@/views/Maestros/Wellboats.vue')
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    component:  () => import('@/views/Maestros/Usuarios.vue')
  },
  {
    path: '/utilitarios',
    name: 'utilitarios',
    component:  () => import('@/views/Maestros/Utilitarios.vue')
  },
  {
    path: '/informacion_general_bitacoras',
    name: 'informacion_general_bitacoras',
    component:  () => import('@/views/Maestros/InformacionGeneral.vue')
  },
  {
    path: '/informacion_general_bitacoras/:id',
    name: 'informacion_general_bitacoras_configurar',
    component:  () => import('@/views/Maestros/InformacionGeneralConfiguracion.vue')
  },
  {
    path: '/reportes_automaticos',
    name: 'reportes_automaticos',
    component:  () => import('@/views/Reportes/CarpetaCompartida.vue')
  },
  {
    path: '/ventas/cliente/mensual',
    name: 'ventas_cliente_mensual',
    component:  () => import('@/views/ReportesFacturacion/VentaClienteMensual.vue')
  },
  {
    path: '/ventas/wellboat/mensual',
    name: 'ventas_wellboat_mensual',
    component:  () => import('@/views/ReportesFacturacion/VentaWellboatMensual.vue')
  },
  {
    path: '/biomasa/mensual/anual',
    name: 'biomasa_mensual_anual',
    component:  () => import('@/views/ReportesFacturacion/BiomasaMensualAnual.vue')
  },
  {
    path: '/biomasa/wellboat/especie',
    name: 'biomasa_wellboat_especie',
    component:  () => import('@/views/ReportesSW/BiomasaWellboatEspecie.vue')
  },
  {
    path: '/viajes/wellboat/especie',
    name: 'viajes_wellboat_especie',
    component:  () => import('@/views/ReportesSW/NroViajeWellboatEspecie.vue')
  },
  {
    path: '/viajes/biomasa/cliente/especie',
    name: 'viajes_biomasa_cliente_especie',
    component:  () => import('@/views/ReportesSW/NroViajeBiomasaClienteEspecie.vue')
  },
  {
    path: '/viajes/biomasa/wellboat',
    name: 'biomasa_mes_anio',
    component:  () => import('@/views/ReportesSW/BiomasaMesAnio.vue')
  },
  {
    path: '/biomasa/mes/anio',
    name: 'viajes_biomasa_wellboat',
    component:  () => import('@/views/ReportesSW/NroViajeBiomasaWellboat.vue')
  },
  {
    path: '/test',
    name: 'test',
    component:  () => import('@/views/Test.vue')
  },
  {
    path: '/solicitud_materiales',
    name: 'solicitud_materiales',
    component:  () => import('@/views/Materiales/SolicitudMateriales.vue')
  },
  {
    path: '/crear_solicitud',
    name: 'crear_solicitud',
    component:  () => import('@/views/Materiales/CreacionSolicitud.vue')
  },
  {
    path: '/revision_solicitudes',
    name: 'revision_solicitudes',
    component:  () => import('@/views/Materiales/RevisionSolicitud.vue')
  },
  {
    path: '/aprobacion_solicitudes',
    name: 'aprobacion_solicitudes',
    component:  () => import('@/views/Materiales/AprobacionSolicitud.vue')
  },
  {
    path: '/solicitud_compras',
    name: 'solicitud_compras',
    component:  () => import('@/views/Materiales/VerSolicitudCompra.vue')
  },
  {
    path: '/solicitud_compras_generar',
    name: 'solicitud_compras_generar',
    component:  () => import('@/views/Materiales/SolicitudCompra.vue')
  },
  {
    path: '/solicitud_retiros',
    name: 'solicitud_retiros',
    component:  () => import('@/views/Materiales/VerSolicitudRetiro.vue')
  },
  {
    path: '/solicitud_retiros_generar',
    name: 'solicitud_retiros_generar',
    component:  () => import('@/views/Materiales/SolicitudRetiro.vue')
  },
  {
    path: '/papeletas_descanso',
    name: 'papeletas_descanso',
    component:  () => import('@/views/PapeletaDescanso/PapeletaDescanso.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    component:  () => import('@/views/Login.vue'),
    beforeEnter: (to, from, next) => {
      store.state.token = null;
      store.state.headers = null;
      store.state.user = {};
      store.state.menu = null;
      store.state.user.wellboat = {id: 0, nombre: 'Ninguno'};
      next();
    }
  },
  {
    path: '*',
    redirect: '/login'
  }
]

// let usar = 'avanze';
let usar = process.env.VUE_APP_USAR_ROUTER_INDEX_JS;
let base = '/';
if(usar == 'nuc' || usar == 'queilen'){
  base = '/produccion/';
}
/*if(usar == 'avanze'){
  base = '/';
}*/

const router = new VueRouter({
  mode: 'history',
  base:process.env.environment === 'development' ? '/' : base,
  routes
});

//funcion que permite controlar el acceso a las rutas del sistema
router.beforeEach(async (to, from, next) => {
  if(to.fullPath === '/' || to.fullPath === '/login'){
    next();
  }else{
    let authorized = false;
    let gotodash = false;
    if(store.state.token){
      if(store.state.user.menus){
        store.state.user.menus.forEach(m => {
          if(m.type == "group"){
            m.children.forEach(ch => {
              if(ch.path == to.name && ch.ver == 1){
                authorized = true;
              }
            });
          }else{
            if(m.path == to.name && m.ver == 1){
              authorized = true;
            }
            if(m.path == "dashboard" && m.ver == 1){
              gotodash = true;
            }
          }
        });
        authorized = true;
        if(authorized){
          if(to.name == "dashboard" && gotodash){
            next();
          }else if(to.name == "dashboard" && gotodash == false){
            next('/dashboard2');
          }else{
            next();
          }
        }else if(to.name == "dashboard2"){
          next();
        }else{
          next('/dashboard2');
        }
      }
    }else{
      next('/');
    }
  }
});

export default router;
