import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        tipoCostos: [],
        tipoCosto: []
    }),
    mutations: {
        createTipoCosto (state, item){
            state.tipoCostos.push(item)
        },
        updateTipoCosto (state, item){
            let pos = state.tipoCostos.findIndex(e => e.id == item.id)
            state.tipoCostos.splice(pos, 1, item)
        },
        deleteTipoCosto(state, index){
            state.tipoCostos.splice(index, 1)
        },
        showTipoCosto(state, tipoCosto){
            let pos = state.tipoCostos.findIndex(e => e.id == tipoCosto.id)
            tipoCosto.mostrar = 1
            state.tipoCostos.splice(pos, 1, tipoCosto)
        },
        hideTipoCosto(state, tipoCosto){
            let pos = state.tipoCostos.findIndex(e => e.id == tipoCosto.id)
            tipoCosto.mostrar = 0
            state.tipoCostos.splice(pos, 1, tipoCosto)
        },
        setTipoCostos(state, tipoCostos){
            state.tipoCostos = tipoCostos
        }
    },
    actions: {
        async createTipoCosto (context, tipoCosto){
            try{
                const data = await axios.post(context.rootState.base_url + 'tipo_costos', context.rootState.headers)
                context.commit("createTipoCosto", data)
            }catch(error){
                console.log(error)
            }
        },
        async updateTipoCosto (context, tipoCosto){
            try{
                const data = await axios.put(context.rootState.base_url + `tipo_costos/${tipoCosto.id}`, context.rootState.headers)
                context.commit("updateTipoCosto", data)
            }catch(error){
                console.log(error)
            }
        },
        async deleteTipoCosto(context, tipoCosto){
            try{
                const data = await axios.delete(context.rootState.base_url + `tipo_costos/${tipoCosto.id}`, context.rootState.headers)
                context.commit("deleteTipoCosto", tipoCosto.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchTipoCostos(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'tipo_costos', context.rootState.headers)
                context.commit("setTipoCostos", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.tipoCostos.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.tipoCostos.find(e => e.id == id)
        },
        getAll: state => {
            return state.tipoCostos
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */