import axios from 'axios'

/* eslint-disable no-unused-vars */
export default {
    state: () => ({
        muelles: [],
        muelle: []
    }),
    mutations: {
        createMuelle (state, item){
            state.muelles.push(item)
        },
        updateMuelle (state, item){
            let pos = state.muelles.findIndex(e => e.id == item.id)
            state.muelles.splice(pos, 1, item)
        },
        deleteMuelle(state, index){
            state.muelles.splice(index, 1)
        },
        showMuelle(state, muelle){
            let pos = state.muelles.findIndex(e => e.id == muelle.id)
            muelle.mostrar = 1
            state.muelles.splice(pos, 1, muelle)
        },
        hideMuelle(state, muelle){
            let pos = state.muelles.findIndex(e => e.id == muelle.id)
            muelle.mostrar = 0
            state.muelles.splice(pos, 1, muelle)
        },
        setMuelles(state, muelles){
            state.muelles = muelles
        }
    },
    actions: {
        async createMuelle (context, muelle){
            try{
                const data = await axios.post(context.rootState.base_url + 'muelles', context.rootState.headers)
                context.commit("createMuelle", data)
            }catch(error){
                console.log(error)
            }
        },
        async updateMuelle (context, muelle){
            try{
                const data = await axios.put(context.rootState.base_url + `muelles/${muelle.id}`, context.rootState.headers)
                context.commit("updateMuelle", data)
            }catch(error){
                console.log(error)
            }
        },
        async deleteMuelle(context, muelle){
            try{
                const data = await axios.delete(context.rootState.base_url + `muelles/${muelle.id}`, context.rootState.headers)
                context.commit("deleteMuelle", muelle.id)
            }catch(error){
                console.log(error)
            }
        },
        async fetchMuelles(context){
            try{
                const resp = await axios.get(context.rootState.base_url + 'muelles', context.rootState.headers)
                context.commit("setMuelles", resp.data)
            }catch(error){
                console.log(error)
            }
        }
    },
    getters: {
        getActive: state => {
            return state.muelles.filter(e => e.mostrar == 1)
        },
        getOne: (state, id) => {
            return state.muelles.find(e => e.id == id)
        },
        getAll: state => {
            return state.muelles
        }
    },
    namespaced: true
}
/* eslint-disable no-unused-vars */