import Vue from 'vue';
import Vuetify from 'vuetify/lib';

import es from 'vuetify/es5/locale/es';

//import { preset } from 'vue-cli-plugin-vuetify-preset-rally/preset'
//import { preset } from 'vue-cli-plugin-vuetify-preset-fortnightly/preset'
import { preset } from 'vue-cli-plugin-vuetify-preset-reply/preset'

import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
  preset,
  lang: {
    locales: { es },
    current: 'es',
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
});
