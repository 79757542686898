<template>
  <div>
    <div class="card">
      <div class="card-header card-header-primary card-header-icon">
        <div :class="`card-icon ${color_icono}`">
          <i class="material-icons" v-show="icono" >{{icono}}</i>
        </div>
        <h4 class="card-title">
          <div class="row">
            <div class="col-md-6">
              {{titulo}}
            </div>
            <div class="col-md-6">
              <v-text-field
                v-model="busqueda"
                append-icon="mdi-magnify"
                :label="label"
                single-line
                hide-details
              ></v-text-field>
            </div>
          </div>
        </h4>
      </div>
      <div class="card-body">
        <div class="toolbar">
          <!--        Here you can write extra buttons/actions for the toolbar              -->
        </div>
        <div class="material-datatables">
          <v-data-table
            :headers="cabeceras"
            :items="items"
            :search="busqueda"
            :header-props="{ sortIcon: 'arrow_upward' }"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'first_page',
              lastIcon: 'last_page',
              prevIcon: 'navigate_befores',
              nextIcon: 'navigate_next'
              //'items-per-page-options':[5,10,20],
            }"
          >
          <template v-slot:[`item.firma`]="{item}" >
            <img v-bind:src="'data:image/jpeg;base64,'+item.firma64" width="240" height="120" v-if="item.firma != null && item.firma.length > 0"/>
          </template>
          <template v-slot:[`item.actions`]="{item}">

            <v-tooltip left >
              <template v-slot:activator="{ on }">
                <i class="material-icons" style="cursor:pointer;color:orange;" @click="$emit('editar',item)" v-show="buscar_accion('editar')" v-on="on">edit</i>
              </template>
              <span>Editar</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <i class="material-icons" style="cursor:pointer;color:#558B2F;" @click="$emit('subir',item)" v-show="buscar_accion('subir')" v-on="on">upload</i>
              </template>
              <span>Cargar</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <i class="material-icons" style="cursor:pointer;color:#0277BD;" @click="$emit('mostrar',item)" v-show="buscar_accion('mostrar') && item.mostrar != 1" v-on="on">visibility</i>
              </template>
              <span>Mostrar</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <i class="material-icons" style="cursor:pointer;color:#BF360C;" @click="$emit('ocultar',item)" v-show="buscar_accion('ocultar') && item.mostrar == 1" v-on="on">visibility_off</i>
              </template>
              <span>Ocultar</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <i class="material-icons" style="cursor:pointer;color:gray;" @click="$emit('configurar',item)" v-show="buscar_accion('configurar')" v-on="on">settings</i>
              </template>
              <span>Configurar</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <i class="material-icons" style="cursor:pointer;color:red;" @click="$emit('eliminar',item)" v-show="buscar_accion('eliminar')" v-on="on">delete</i>
              </template>
              <span>Eliminar</span>
            </v-tooltip>

            

          </template>

          <!-- Mantenedor Centros de Cultivo -->
            <template v-slot:[`item.empresa.razon_social`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.empresa.razon_social }}</p>
              <p v-else class="text-decoration-line-through">{{ item.empresa.razon_social }}</p>
            </template>
            <template v-slot:[`item.nombre`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.nombre }}</p>
              <p v-else class="text-decoration-line-through">{{ item.nombre }}</p>
            </template>
            <template v-slot:[`item.latitud`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.latitud }}</p>
              <p v-else class="text-decoration-line-through">{{ item.latitud }}</p>
            </template>
            <template v-slot:[`item.longitud`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.longitud }}</p>
              <p v-else class="text-decoration-line-through">{{ item.longitud }}</p>
            </template>
            <!-- Mantenedor Centros de Cultivo -->

            <!-- Mantenedor de Clientes (Empresas.vue) -->
            <template v-slot:[`item.rut`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.rut }}</p>
              <p v-else class="text-decoration-line-through">{{ item.rut }}</p>
            </template>
            <template v-slot:[`item.razon_social`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.razon_social }}</p>
              <p v-else class="text-decoration-line-through">{{ item.razon_social }}</p>
            </template>
            <template v-slot:[`item.giro`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.giro }}</p>
              <p v-else class="text-decoration-line-through">{{ item.giro }}</p>
            </template>
            <template v-slot:[`item.direccion`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.direccion }}</p>
              <p v-else class="text-decoration-line-through">{{ item.direccion }}</p>
            </template>
            <template v-slot:[`item.fono`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.fono }}</p>
              <p v-else class="text-decoration-line-through">{{ item.fono }}</p>
            </template>
            <template v-slot:[`item.email`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.email }}</p>
              <p v-else class="text-decoration-line-through">{{ item.email }}</p>
            </template>
            <template v-slot:[`item.logo`]="{item}" v-if="tachar">
              <span v-if="item.logo != null && item.logo.length > 0">Si</span>
              <span v-if="item.logo == null || item.logo.length == 0">No</span>
            </template>
            <!-- Mantenedor de Clientes (Empresas.vue) -->

            <!-- Mantenedor de Especies -->
            <template v-slot:[`item.abreviatura`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.abreviatura }}</p>
              <p v-else class="text-decoration-line-through">{{ item.abreviatura }}</p>
            </template>
            <!-- Mantenedor de Especies -->

            <!-- Mantenedor de Wellboats -->
            <template v-slot:[`item.capacidad`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.capacidad }}</p>
              <p v-else class="text-decoration-line-through">{{ item.capacidad }}</p>
            </template>
            <template v-slot:[`item.sistema`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.sistema }}</p>
              <p v-else class="text-decoration-line-through">{{ item.sistema }}</p>
            </template>
            <template v-slot:[`item.nudos_vacio`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.nudos_vacio }}</p>
              <p v-else class="text-decoration-line-through">{{ item.nudos_vacio }}</p>
            </template>
            <template v-slot:[`item.nudos_cargado`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.nudos_cargado }}</p>
              <p v-else class="text-decoration-line-through">{{ item.nudos_cargado }}</p>
            </template>
            <template v-slot:[`item.tiempo_carga`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.tiempo_carga }}</p>
              <p v-else class="text-decoration-line-through">{{ item.tiempo_carga }}</p>
            </template>
            <template v-slot:[`item.tipo_descarga.nombre`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.tipo_descarga.nombre }}</p>
              <p v-else class="text-decoration-line-through">{{ item.tipo_descarga.nombre }}</p>
            </template>
            <template v-slot:[`item.toneladas`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.toneladas }}</p>
              <p v-else class="text-decoration-line-through">{{ item.toneladas }}</p>
            </template>
            <template v-slot:[`item.m3_bodegas`]="{item}">
              <p class="text-left" v-html="item.m3_bodegas"></p>
            </template>
            <!-- Mantenedor de Wellboats -->

            <!-- Mantenedor de Usuarios -->
            <template v-slot:[`item.name`]="{item}">
              <p v-if="item.mostrar == 1">{{ item.name }}</p>
              <p v-else class="text-decoration-line-through">{{ item.name }}</p>
            </template>
            <template v-slot:[`item.wellboat.nombre`]="{item}" v-if="tachar">
              <p v-if="item.mostrar == 1">{{ item.wellboat?item.wellboat.nombre:'' }}</p>
              <p v-else class="text-decoration-line-through">{{ item.wellboat?item.wellboat.nombre:'' }}</p>
            </template>
            <template v-slot:[`item.rol.nombre`]="{item}">
              <p v-if="item.mostrar == 1">{{ item.rol?item.rol.nombre:'' }}</p>
              <p v-else class="text-decoration-line-through">{{ item.rol?item.rol.nombre:'' }}</p>
            </template>
            <!-- Mantenedor de Usuarios -->

          </v-data-table>
        </div>
      </div>
      <!-- end content-->
    </div>
    <!--  end card  -->
    
  </div>
</template>
<script>
  import { VDataTable } from 'vuetify/lib'

  export default {
    name: 'DataTable',
    props: ['cabeceras','items','icono','titulo','acciones','label','color_icono','tachar'],
    data:()=>({
        busqueda:'',
    }),
    extends: VDataTable,
    methods: {
      buscar_accion(accion){
        let found = this.acciones.find(acc=>acc == accion);
        if(found){
          return true;
        }else{
          return false;
        }
      }
    },
    directives: {
      nl2br: {
        inserted(el) {
          // simplified example, 
          // works only for nodes without any child elements
          el.innerHTML = el.textContent.replace(/\n/g, '<br />')
        }
      }
    }
  }
</script>
<style>
  .azul{
    background: #1565C0 !important;
  }
  .white-text{
    color: #fff !important;
  }
  .theme--light.v-data-table .v-data-table-header th.sortable .v-data-table-header__icon {
    color: #fff !important;
  }
  .theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon {
    color:#fff !important;
  }
</style>